import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`
const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is GitLab?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "GitLab is a Git-based repository manager and a powerful application for software development. GitLab allows users to work effectively from the command line and UI itself."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is Difference Between Git And GitLab?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Git is a free and open source designed to handle everything from small to large projects with speed and maximum efficiency. GitLab on the other hand is open-source self-hosted Git software. With Codestaff, you are sure to get the best GitLab service."
        }
    }
}

const Gitlab = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> GitLab Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top GitLab Developers. Top
                                    companies and start-ups choose Codestaff expert GitLab
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top GitLab Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE GITLAB EXPERTS' banner='/gitlab.png' bannerAlt='gitlab banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Gitlab