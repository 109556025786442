import React from 'react'
import Layout from '../components/layout'
import Gitlab from '../components/categories/gitlab'
import Seo from '../components/seo'

const GitlabPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Gitlab />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Gitlab Freelancers | Codestaff'
  const description =
    'Hire the best Gitlab freelancers at Codestaff. Get the top 1% of Gitlab professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default GitlabPage
